<template>
  <div>
    <main class="main">
      <div class="col_main">
        <h2>
          <div class="title">{{ title }}</div>
          <p class="button" @click="backFromImage()" v-if="selectedImage">戻る</p>
          <p class="button" @click="downloadImage()" v-if="!tableauInfo.images && !noImage">画像ダウンロード</p>
          <vue-select
            placeholder="画像 ダウンロード"
            :clearable="false"
            :searchable="false"
            label="title"
            :options="tableauInfo.images"
            v-model="selectedImage"
            v-if="tableauInfo.images"
          ></vue-select>
          <vue-select
            placeholder="CSV ダウンロード"
            :clearable="false"
            :searchable="false"
            label="title"
            :options="tableauInfo.csv"
            v-model="selectedDownload"
            v-if="tableauInfo.csv"
          ></vue-select>
          <p class="button" @click="reload()">抽出条件をクリア</p>
        </h2>
        <div class="vizContainer_wrapper">
          <div class="col_main__block" id="vizContainer"></div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import _MENU_JSON from '../assets/data/menu.json';
import {TableauEventType, TableauViz, TableauEventName, CrosstabFileFormat} from '../assets/js/tableau.embedding.3.latest.min.js';

export default {
  name: 'analysis',
  components: { 'vue-select': vSelect },
  data() {
    return {
      category: this.$route.query.category,
      name: this.$route.query.name,
      title: '',
      tableauInfo: {},
      viz: {},
      selectedDownload: '',
      selectedImage: '',

      noImage: false,
      currentSheet: null,
    };
  },
  created() {
    this.$loadScript('https://10ax.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js').then(() => {
      this.initViz();
    });

    let categoryArray = _MENU_JSON.find((el) => el.title == this.category);
    let itemArray = categoryArray['items'].find((el) => el.title == this.name);
    console.log(itemArray)
    if (!this.checkPermission(categoryArray['title'], itemArray['title'])) {
      location.href = '/';
    }

    this.tableauInfo = _MENU_JSON.find((el) => el.title == this.category)['items'].find((el) => el.title == this.name)['tableau'];

    this.title = categoryArray['longtitle'] ?? categoryArray['title'];
    this.title += ' > ';
    this.title += itemArray['longtitle'] ?? itemArray['title'];
  },
  methods: {
    initViz: function() {
        const viz = new TableauViz();   // create the authoring viz object
        this.viz = viz;

        // if (this.title === '薬剤全般 > 後発医薬品使用体制加算' || this.title === '薬剤全般 > 経済性フォーミュラリー') {
        //   let self = this;
        //   options.onFirstInteractive = function() {
        //     self.listenToMarksSelection();
        //   };
        // }
        let vizurl = process.env.VUE_APP_TABLEAU_URI;
        switch (process.env.NODE_ENV) {
          case 'production':
            vizurl += this.tableauInfo.vizurl;
            break;
          case 'staging':
            vizurl += this.tableauInfo.vizurl_stg;
            break;
          default:
            vizurl += this.tableauInfo.vizurl_dev;
        }

        var token = this.$auth.user['https://bi-ph2.jmdc-qi.com/tableau_token'];
        console.log('TOKEN', token)

        viz.src = vizurl;
        viz.height = this.tableauInfo.height;
        viz.width = this.tableauInfo.width;
        viz.toolbar = 'hidden';
        viz.hideTabs = 'true';
        viz.token = token;
        viz.addEventListener(TableauEventType.FirstInteractive, () => this.listenToMarksSelection())
        viz.addEventListener(TableauEventType.TabSwitched, () => this.onMarksSelection());
        // add viz to HTML code
        document.getElementById('vizContainer').appendChild(viz);
    },
    // initViz2: function() {
    //   let containerDiv = document.getElementById('vizContainer');

    //   let options = {
    //     hideTabs: true,
    //     hideToolbar: true,
    //     device: 'desktop',
    //     width: this.tableauInfo.width,
    //     height: this.tableauInfo.height,
    //     onFirstInteractive: function() {
    //       self.listenToMarksSelection();
    //     },
    //     token: this.$auth.user['https://bi-ph2.jmdc-qi.com/tableau_token']
    //   };
    //   if (this.title === '薬剤全般 > 後発医薬品使用体制加算' || this.title === '薬剤全般 > 経済性フォーミュラリー') {
    //     let self = this;
    //     options.onFirstInteractive = function() {
    //       self.listenToMarksSelection();
    //     };
    //   }
    //   let vizurl = process.env.VUE_APP_TABLEAU_URI;
    //   switch (process.env.NODE_ENV) {
    //     case 'production':
    //       vizurl += this.tableauInfo.vizurl;
    //       break;
    //     case 'staging':
    //       vizurl += this.tableauInfo.vizurl_stg;
    //       break;
    //     default:
    //       vizurl += this.tableauInfo.vizurl_dev;
    //   }
    //   this.viz = new window.tableau.Viz(containerDiv, vizurl, options);
    //   this.viz.token = this.$auth.user['https://bi-ph2.jmdc-qi.com/tableau_token'];
    //   console.log(this.$auth.user['https://bi-ph2.jmdc-qi.com/tableau_token'])
    // },

    listenToMarksSelection: function() {

      this.onMarksSelection()
    },
    onMarksSelection: function(marksEvent) {
      const sheetName = this.viz.workbook.activeSheet?.name;
      if (!this.selectedImage) {
        this.currentSheet = sheetName;
      }

      if (sheetName && sheetName in this.tableauInfo) {
        this.tableauInfo.csv = this.tableauInfo[sheetName];
      }
      if (sheetName && sheetName + '_images' in this.tableauInfo) {
        this.tableauInfo.images = this.tableauInfo[sheetName + '_images'];
      }

     if (sheetName == '医薬品の内訳' || sheetName == '医薬品内訳' || sheetName == '成分内訳') {
        this.noImage = true;
      } else {
        this.noImage = false;
      }

      let height = this.tableauInfo.height;
      if (sheetName === '経済性シミュレーション_詳細') {
        height = this.tableauInfo.height_detail;
      }
      this.viz.setFrameSize(parseInt(this.tableauInfo.width, 10), parseInt(height, 10));
    },

    downloadImage: function() {
      /*this.viz
        .getWorkbook()
        .getActiveSheet()
        .getWorksheets()
        .get('1-1.入棟数(全体-年)')
        .applyRangeFilterAsync('入院日', { min: new Date(Date.UTC(2010, 0, 1)), max: new Date(Date.now()) });*/

      //this.viz.showExportCrossTabDialog();
      // this.viz.showExportImageDialog();
      // this.viz.displayDialogAsync(TableauDialogType.ExportPDF);
      this.viz.exportImageAsync();
    },
    backFromImage: function() {
      this.selectedImage = null;
      
      if (this.currentSheet != null) {
        console.log('BackTo', this.currentSheet)
        this.viz.workbook.activateSheetAsync(this.currentSheet);  
      } else {
        console.log('BackTo', this.tableauInfo.home_sheet)
        this.viz.workbook.activateSheetAsync(this.tableauInfo.home_sheet);
      }
    },
    downloadImageWithName: function(worksheet) {
      console.log(worksheet)

      this.viz.workbook.activateSheetAsync(worksheet).then(() => {
        // this.viz.showExportImageDialog();
        // this.viz.displayDialogAsync(TableauDialogType.ExportPDF);
        this.viz.exportImageAsync();
      });
    },
    downloadCsv: function(worksheet) {
      // console.log(this.viz, worksheet);
      // this.viz.showExportCrossTabDialog(worksheet);
      const crosstabFileFormat = CrosstabFileFormat.CSV;
      this.viz.exportCrosstabAsync(worksheet, crosstabFileFormat);
    },
    reload: function() {
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    checkPermission(category) {
      let allow_dashboard = this.$auth.user['https://bi-ph2.jmdc-qi.com/allow_dashboard'];
      let allow_dashboard_parts = allow_dashboard.map(function(dashboard) {
            return dashboard.split('$')[0];
        });
      return allow_dashboard_parts.includes(category);
    },
  },
  beforeDestroy() {
    this.viz.dispose();
  },
  watch: {
    selectedDownload(value) {
      if (value.worksheet) {
        console.log('Download:', value.worksheet);
        console.log('Sheets:', this.viz.workbook.publishedSheetsInfo)
        this.downloadCsv(value.worksheet);
        this.selectedDownload = '';
      }
    },
    selectedImage(value) {
      if (value && value.worksheet) {
        this.downloadImageWithName(value.worksheet);
      }
    }
  },
};
</script>

<style scoped lang="scss">
.col_main {
  width: 100%;
  h2 {
    background: #ebebe0;
    box-shadow: 5px 5px 5px rgba(23, 23, 23, 0.05) inset, -5px -5px 5px rgba(23, 23, 23, 0.05) inset;
    font-size: 2.6rem;
    font-weight: bold;
    align-items: center;
    margin: 10px auto 24px;
    padding: 2px 10px;

    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-end;
    .title {
      margin-right: auto;
    }
  }
}
.button {
  cursor: pointer;
  height: 3.4rem;
  margin: 1px 10px 0 0;
  border: 1px solid #9c9c9c;
  border-radius: 4px;
  padding: 5px 16px;
  color: var(--text-color);
  background-color: #fbfbfb;
  text-align: center;
  white-space: nowrap;
  font-size: 1.6rem;
  &:hover {
    background-color: #e8ffe2;
  }
}
.vizContainer_wrapper {
  text-align: center;
  overflow-x: auto;
  #vizContainer {
    display: inline-block;
  }
}
.v-select {
  width: 380px;
  font-size: 1.6rem;
  margin: 1px 10px 0 0;
  color: var(--text-color);
  background-color: #fbfbfb;
  letter-spacing: -1.5px;
  ::v-deep .vs__dropdown-toggle {
    border: 1px solid #9c9c9c;
  }
  ::v-deep .vs__search,
  ::v-deep .vs__search:focus {
    margin: 5px 0 -2px;
    color: var(--text-color);
    font-weight: 700;
    font-size: 1.6rem;
    white-space: nowrap;
    cursor: pointer;
  }
  ::v-deep .vs__dropdown-option--highlight {
    background: var(--regincl-color) !important;
  }
}
::v-deep .vs--searchable .vs__dropdown-toggle {
  cursor: pointer;
}

.select {
  font-size: 1.6rem;
  height: 3.4rem;
  padding: 5px 6px;
  margin-right: 14px;
  color: var(--text-color);
  background-color: #fbfbfb;
  border: 1px solid #9c9c9c;
  border-radius: 4px;
  &:hover {
    background-color: #e8ffe2;
  }
}
</style>
